import img1 from "../../assets/Chifon.jpg";
import img2 from "../../assets/11.png";

function Chiflón() {
  return (
    <div>
      <style>
        {`
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }
          header {
            background-color: #333;
            color: #ffff;
            padding: 20px;
            margin-top: 50px;
            text-align: center;
          }
          main {
            padding: 20px;
          }
          h1 {
            color: #333;
            text-align: center;
          }
          p {
            text-align: center;
          }
          .image-container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }
          .image-container img {
            width: 300px;
            height: auto;
            margin: 0 10px;
          }
        `}
      </style>
      <header id="head">
        <h2 className="text-white">¡Viaja en moto a La Chorrera con 4rent!</h2>
      </header>
      <main>
        <h3>Explora La Cascada Más Alta de Colombia</h3>
        <div className="image-container">
          <img src={img1} alt="La Chorrera 1" />
        </div>
        <p>
          Sumérgete en la belleza natural de La Chorrera, ubicada en Choachí, un destino reconocido por la Organización Mundial de Turismo como uno de los mejores pueblos para visitar.
        </p>
        <div className="image-container">        
          <img src={img2} alt="La Chorrera 2" />
        </div>
      </main>
    </div>
  );
}

export default Chiflón;
