
import { Link } from "react-router-dom";
import { FaArrowDown } from "react-icons/fa";
import "./Head.css"; 

const uno = "https://res.cloudinary.com/djrawtoxf/image/upload/v1718643601/4rent/d96fma7spcc3fqnjtaqa.webp";

const Head = () => {
  return (
    <header className="hero-header" style={{ backgroundImage: `url(${uno})` }}>
      <div className="hero-header__background" />
      <div className="hero-header__overlay" />
      <div className="hero-header__content">
        <p className="hero-header__main-text">¡Bienvenidos a 4Rent!</p>
        <h1 className="hero-header__sub-text">Alquiler de motos en Bogotá y Medellín</h1>
        <p className="hero-header__description">
          Alquila una moto y vive la mejor experiencia en Colombia.
        </p>
        <div className="hero-header__link-container">
          <Link to="/motocicletas" className="hero-header__link">
            <p>Motos</p>
          </Link>
        </div>
      </div>
      <div className="hero-header__icon">
        <FaArrowDown size="1rem" color="#fffff" />
      </div>
    </header>
  );
};

export default Head;
