
import img2 from "../../assets/Chicaque.png";


function Parques() {
  return (
    <div>
      <style>
        {`
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }
          header {
            background-color: #333;
            color: #ffff;
            padding: 20px;
            margin-top: 50px;
            text-align: center;
          }
          main {
            padding: 20px;
          }
          h1, h3, h4 {
            color: #333;
            text-align: center;
          }
          p {
            text-align: justify;
            margin-bottom: 20px;
          }
          .image-container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }
          .image-container img {
            width: 300px;
            height: auto;
            margin: 0 10px;
          }
        `}
      </style>
      <header>
        <h2 className="text-white">¡Alquila en 4 rent y viaja al Parque Chipaque!</h2>
      </header>
      <main>
  <h3>Descubre Chipaque: Historia, Geografía y Turismo</h3>
  <p>
    Chipaque es un hermoso municipio ubicado en el departamento de Cundinamarca, en la Provincia de Oriente, a unos 14 kilómetros al sur-oriente de Bogotá. Su nombre, en muysc cubun (idioma muisca), significa &quot;bosque de nuestro padre&quot;, según el diccionario de Joaquín Acosta Ortegón.
  </p>
  <p>
    La historia de Chipaque se remonta a tiempos precolombinos, perteneciendo al cacicazgo de Ubaque. Su fundación hispánica se atribuye al conquistador Juan Tafur, con la construcción de su templo en 1601. A lo largo de los siglos, Chipaque ha sido testigo de diversos eventos históricos, como la creación de su parroquia en 1731 y su participación en la independencia americana.
  </p>
  <div className="image-container">

    <img src={img2} alt="Imagen 2" />
  </div>
  <p>
    Chipaque se encuentra enclavado en la región geográfica de Oriente, rodeado por las Provincias del Guavio, Oriente y Medina. Con una extensión total de 139.45 km², su altitud media es de 2400 m s. n. m. y su temperatura media es de 13 °C. Su ubicación estratégica lo hace accesible desde Bogotá a través de la Ruta Nacional 40.
  </p>

  <h4>Turismo</h4>
  <p>
    Además de su rica historia y geografía, Chipaque ofrece diversas atracciones turísticas, como su iglesia parroquial, el Pueblo Viejo de Mongue, el Cerro de Bochica, la Reserva de Marilandia, las ferias y fiestas tradicionales, entre otros. Los visitantes también pueden disfrutar de la deliciosa gastronomía local y explorar los senderos turísticos que ofrece el municipio.
  </p>

</main>

    </div>
  );
}

export default Parques;
