import  { useState } from 'react';
import { tarifasIncluyen, tarifasNoIncluyen, restricciones } from '../../db/mongo';
import './TarifasComponent.css';
const TarifasComponent = () => {
  const [tarifasIncluidas] = useState(tarifasIncluyen);
  const [tarifasNoIncluidas] = useState(tarifasNoIncluyen);
  const [condicionesUso] = useState(restricciones);

  return (
    <div className="tarifas-container">
      <div className="tarifas-incluyen card">
        <h2>Tarifas que incluyen:</h2>
        {tarifasIncluidas.map((tarifa, index) => (
          <div key={index} className="tarifa-card">
            <p>{tarifa}</p>
          </div>
        ))}
      </div>
      <div className="tarifas-no-incluyen card">
        <h2>Tarifas que no incluyen:</h2>
        {tarifasNoIncluidas.map((tarifa, index) => (
          <div key={index} className="tarifa-card">
            <p>{tarifa}</p>
          </div>
        ))}
      </div>
      <div className="restricciones card">
        <p>{condicionesUso}</p>
      </div>
    </div>
  );
};
export default TarifasComponent;
