
import './VariedadMotos.css'; // Importa el archivo CSS para los estilos

function VariedadMotos() {
  return (
    <div className="variedad-motos-container"> {/* Usa una clase para el contenedor principal */}
      <h1>Explora En 4Rent Variedad de Motocicletas en Óptimo Estado</h1>
      <p>
        ¡Bienvenido a 4rent, donde la aventura sobre ruedas cobra vida! En nuestra apasionante gama de motocicletas, encontrarás algo que se adapte perfectamente a tus necesidades y deseos. Nos enorgullece presentarte nuestra selección de motos, cada una meticulosamente mantenida y lista para llevar tus travesías al siguiente nivel.
      </p>
      <h3>Motocicletas en Excelente Estado:</h3>
      <p>
        Nuestra prioridad es tu seguridad y satisfacción, por lo que nos aseguramos de que cada motocicleta en nuestro catálogo esté en óptimo estado. Desde motos deportivas hasta cruceros clásicos, nuestras máquinas son mantenidas por expertos mecánicos para garantizar un rendimiento impecable en cada viaje.
      </p>
      <h3 className="subtitulo">Adaptadas a tus Necesidades y Preferencias:</h3>
      <p>
        Entendemos que cada conductor es único, con diferentes preferencias y estilos de conducción. Es por eso que ofrecemos una variedad de modelos que se adaptan a una amplia gama de necesidades y preferencias. Ya sea que busques una moto ágil para recorrer la ciudad o una poderosa máquina para aventuras en carretera, tenemos la moto perfecta para ti.
      </p>
      <h3 className="subtitulo">Experimenta la Emoción:</h3>
      <p>
        No se trata solo de conducir, se trata de vivir la emoción en cada curva y recta del camino. Con nuestras motocicletas en excelente estado, puedes sentirte seguro y confiado mientras exploras nuevos horizontes. Únete a nuestra comunidad de entusiastas de las dos ruedas y descubre la libertad y la emoción que solo una moto puede ofrecer.
      </p>
      <div className="contenido-adicional">
        <p>
          En resumen, en nuestra página, encontrarás una amplia selección de motocicletas, todas en perfecto estado y adaptadas a tus necesidades y preferencias individuales. ¿Estás listo para comenzar tu próxima aventura sobre ruedas? ¡Explora nuestro catálogo y sumérgete en el emocionante mundo de las motocicletas!
        </p>
      </div>
    </div>
  );
}

export default VariedadMotos;
