
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
const Nmax = "https://res.cloudinary.com/diyruqkor/image/upload/v1725539765/4%20rent/ilj6dpxlaeloit4lczrx.webp";
const Fz250 = "https://res.cloudinary.com/diyruqkor/image/upload/v1725540217/4%20rent/bouuuwh8xfkywjvs5okt.webp";
const Dominar400 = "https://res.cloudinary.com/diyruqkor/image/upload/v1725540217/4%20rent/uamvp4r4dmmm6mlzwjoa.webp";
const Xre300 =  "https://res.cloudinary.com/diyruqkor/image/upload/v1725552509/4%20rent/vc5o46peiows0sze6rhj.webp";
const Xtz150 =  "https://res.cloudinary.com/diyruqkor/image/upload/v1725540217/4%20rent/dyw5bcgg8ydky1jx6moz.webp";
const Xpulse200 =  "https://res.cloudinary.com/diyruqkor/image/upload/v1725540217/4%20rent/lb9ywauerknevt4fcgk8.webp";
const fz250_hero =  "https://res.cloudinary.com/diyruqkor/image/upload/v1725540217/4%20rent/bouuuwh8xfkywjvs5okt.webp";

import './Motocicletas.css';

const motoList = [
    {
        link: "../fz250",
        name: "FZ 250 ABS",
        price: "Sportbike - Motocicleta deportiva",
        image: Fz250,
        cilindraje: "250cc",
        marca: "Yamaha",
    },
    {
        link: "../nmax",
        name: "NMAX 155 ABS",
        price: "Scooter Automática para turismo",
        image: Nmax,
        cilindraje: "155cc",
        marca: "Yamaha",
    },
    {
        link: "../xpulse200",
        name: "XPULSE 200",
        price: "Motocicleta doble propósito",
        image: Xpulse200,
        cilindraje: "200cc",
        marca: "Hero",
    },
    {
        link: "../dominar400",
        name: "DOMINAR 400 ABS",
        price: "Moto para viajar por Colombia",
        image: Dominar400,
        cilindraje: "400cc",
        marca: "Bajaj",
    },
    {
        link: "../xre300",
        name: "XRE 300",
        price: "Dual Sport Motorcycle - Motocicleta Todo terreno",
        image: Xre300,
        cilindraje: "300cc",
        marca: "Honda",
    },
    {
        link: "../xtz150",
        name: "XTZ 150",
        price: "Motocicleta todo terreno",
        image: Xtz150,
        cilindraje: "150cc",
        marca: "Yamaha",
    },
];

const Motocicletas = ({ phoneNumbertow }) => {
    const openWhatsAppone = () => {
        window.open(`https://wa.me/${phoneNumbertow}`, '_blank');
    };

    return (
        <>
            <div className="hero-section">
                <div
                    className="hero-background"
                    style={{ backgroundImage: `url(${fz250_hero})` }}
                ></div>
                <div className="hero-overlay"></div>
                <div className="hero-content">
                    <p className="hero-title">Todas nuestras motos 4rent</p>
                    <p className="hero-subtitle">Conoce las motos que tenemos para ti.</p>
                    <div className="contact-buttonOne">
                        <Link
                            onClick={openWhatsAppone}
                            to="https://wa.me/57304589898"
                            className="contact-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            
                        >
                            Contáctanos
                        </Link>
                    </div>
                </div>
            </div>
<div className="home-container">
      <div className="motos-container">
        <div className="motos-inner-container">
          <h1 className="section-title" style={{ color: 'black' }}>Catálogo de Motos para Rentar</h1>
          <p className="section-subtitle">¿Qué tipo de moto deseas alquilar hoy?</p>
          <div className="motos-grid">
            {motoList.map((data, index) => (
              <div key={index} className="moto-card">
                <Link to={data.link} className="moto-link">
                  <div className="moto-name">{data.name}</div>
                  <div className="moto-image-container">
                    <img src={data.image} alt={data.name} className="moto-image" />
                  </div>
                  <div className="moto-details">
                    <p className="moto-brand">{data.marca}</p>
                    <p className="moto-engine">{data.cilindraje}</p>
                  </div>
                  <div className="moto-price">{data.price}</div>
                  <div className="view-prices">VER PRECIOS AQUÍ</div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

            <div className="rental-section">
                <div>
                    <a
                        className="rent-now-link"
                        onClick={openWhatsAppone}
                        href="https://wa.me/57304589898"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        ¡Alquila Ahora!
                    </a>
                </div>
            </div>
            <div className="info-section">
                <h3 className="info-title">Encuentra tu moto ideal en 4 rent Bogotá</h3>
                <p className="info-text">Te ofrecemos una amplia gama de opciones para que encuentres la moto perfecta para tus necesidades de movilidad. Con precios competitivos y un proceso de alquiler rápido y sencillo, estamos aquí para hacer que tu experiencia sea lo más conveniente posible.</p>
                <p className="info-text">Contamos con una variedad de motos disponibles para alquiler, desde modelos clásicos hasta las últimas novedades. Ya sea que necesites una moto para desplazamientos diarios por la ciudad o para aventuras más largas, tenemos la moto adecuada para ti.</p>
                <p className="info-text">Nuestro proceso de alquiler es rápido y sin complicaciones. Solo necesitas tu identificación, licencia de conducción y una forma de pago válida para comenzar a disfrutar de tu moto alquilada. ¡Es así de fácil!</p>
                <p className="info-text">Nuestro equipo está aquí para ayudarte en cada paso del proceso. Ya sea que tengas preguntas sobre nuestras opciones de alquiler o necesites asistencia durante tu período de alquiler, estamos disponibles para brindarte el apoyo que necesitas.</p>
                <p className="info-text">No esperes más para disfrutar de la libertad y la emoción de conducir una moto por las calles de Bogotá. ¡Reserva tu moto con nosotros y comienza tu próxima aventura sobre dos ruedas hoy mismo!</p>
            </div>
        </>
    );
};

Motocicletas.propTypes = {
    phoneNumbertow: PropTypes.string.isRequired,
};

export default Motocicletas;
