import { lazy, Suspense} from 'react';
import { Link } from "react-router-dom";
import HeroHeader from "../components/HeroHeader/Head";
import WhatsappInvite from "../components/whatsapp/WhatsappInvite";
const Fz250 = "https://res.cloudinary.com/diyruqkor/image/upload/v1725540217/4%20rent/bouuuwh8xfkywjvs5okt.webp";
const Xpulse200 = "https://res.cloudinary.com/diyruqkor/image/upload/v1725540217/4%20rent/lb9ywauerknevt4fcgk8.webp";
const Nmax = "https://res.cloudinary.com/diyruqkor/image/upload/v1725539765/4%20rent/ilj6dpxlaeloit4lczrx.webp";
import "./Home.css";
const Razones = lazy(() => import("../components/StepsComponent/StepsComponent"));
const Botones = lazy(() => import("../components/RequisitosCondicionesComponent/RequisitosCondicionesComponent"));
const MotoRentBogota = lazy(() => import("../components/MotoRentBogota"));
const Hero = lazy(() => import('../components/Hero/Hero'));

const motoList = [
  {
    link: "../fz250",
    name: "FZ 250 ABS",
    price: "Sportbike - Motocicleta deportiva",
    image: Fz250,
    cilindraje: "250cc",
    marca: "Yamaha",
  },
  {
    link: "../nmax",
    name: "NMAX 155 ABS",
    price: "Scooter Automática para turismo",
    cilindraje: "155cc",
    image: Nmax,
    marca: "Yamaha",
  },
  {
    link: "../xpulse200",
    name: "XPULSE 200",
    price: "Motocicleta doble propósito",
    image: Xpulse200,
    cilindraje: "200cc",
    marca: "Hero",
  },
];

const Home = () => {
  return (
    <section id="alquilerdemotos" className="home-container">
      <HeroHeader animationTime={4444} />
      <main>
<div className="motos-container">
  <div className="motos-inner-container">
    <h2 className="section-title" style={{ color: "black" }}>Conoce nuestras motos</h2>
    <h3 className="section-subtitle">Vive la aventura, contáctanos hoy y alquila una de nuestras motos</h3>
    <div className="motos-grid">
      {motoList.map((data, index) => (
        <div key={index} className="moto-card">
          <Link to={data.link} className="link-sin-estilo">
            <div className="moto-info">
              <p className="moto-name">{data.name}</p>
            </div>
            <div className="moto-image-container">
              <img
                src={data.image}
                alt={data.name}
                className="moto-image"
                loading="lazy"
              />
            </div>
            <div className="moto-details">
              <p className="moto-brand">{data.marca}</p>
              <p className="moto-engine">{data.cilindraje}</p>
            </div>
            <div className="moto-price-container">
              <h2 className="moto-price">{data.price}</h2>
            </div>
            <div className="view-prices-container">
              <p className="view-prices">VER PRECIOS AQUÍ</p>
            </div>
          </Link>
        </div>
      ))}
    </div>
    <div className="view-all-button">
      <Link to="/motocicletas" className="btn-primary">
        Ver todas las motos
      </Link>
    </div>
  </div>
</div>

        <Suspense fallback={<div>Loading...</div>}>
          <Razones />
          <Hero />
          <MotoRentBogota />
          <Botones />
        </Suspense>

        <WhatsappInvite phoneNumber="+573154573482" />
      </main>



      <footer className="contact-container">
  <div className="contact-inner-container">
    <div className="contact-content">
      <div className="contact-description-container">
        <h2 className="contact-title" style={{color:"black"}}>
          ¡Reserva tu moto con 4 Rent ahora mismo!
        </h2>
        <p className="contact0-description">
          Experimenta la emoción de recorrer las calles de Bogotá y Medellín sobre dos ruedas. ¡No esperes más para vivir esta aventura única!
        </p>
        <p className="contact0-description">
          En 4Rent, ofrecemos una amplia gama de motos para alquilar en la ciudad de Medellín y Bogotá, Colombia. Tenemos la moto perfecta para ti.
        </p>
        <p className="contact0-description">
          Nuestro objetivo es brindarte una experiencia de alquiler sin complicaciones para que puedas disfrutar al máximo tu viaje.
        </p>
      </div>
      <div className="contact-button-container">
        <Link to="/contacto" className="btn-primary">
          Alquila tu moto
        </Link>
      </div>
    </div>
  </div>
</footer>







    </section>
  );
};

export default Home;
