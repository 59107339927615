import { useState, useEffect } from 'react';
import "./SameMotos.css";
import TarifasComponent from './TarifasComponent';
import axios from 'axios';
import messages from './messages'; 
import ButtonWhatsApp from "./ButtonWhatsApp";
import { TbCircuitMotor, TbCylinderPlus} from "react-icons/tb";
import { AiOutlineSisternode } from "react-icons/ai";
import { SlEnergy } from "react-icons/sl";
import { GiGasPump, GiHorseHead ,GiSpeedometer , GiFullMotorcycleHelmet, GiLifeJacket } from "react-icons/gi";
import { CgChanel } from "react-icons/cg";
import { SiVirginmedia } from "react-icons/si";
import { FaBoxArchive } from "react-icons/fa6";
import { IoPhonePortraitSharp } from "react-icons/io5";
import { RiSecurePaymentFill } from "react-icons/ri";
import { MdPermPhoneMsg } from "react-icons/md";
import { GiColombia } from "react-icons/gi";
import { TbCarCrane } from "react-icons/tb";
import nmax155Image1 from '../../assets/NMAX01.png';
import nmax155Image2 from '../../assets/NMAX02.png';
import nmax155Image3 from '../../assets/NMAX04.png';
import { Link } from 'react-router-dom';
import fz250_hero from "../../assets/Nmax.png"
function Nmax() {
  
  const phoneNumber = "3154573482"; 
  const message = messages.page3; 
  const [tasaDeCambio, setTasaDeCambio] = useState(null);
  const cantidadEnPesos = 49999;
  useEffect(() => {
    const fetchTasaDeCambio = async () => {
      try {
        const response = await axios.get('/obtener_tasa_de_cambio.php');
        setTasaDeCambio(response.data.conversion_rates.USD);
      } catch (error) {
        console.error('Error al obtener la tasa de cambio:', error);
      }
   };
    fetchTasaDeCambio();
  }, []);
  const convertirADolares = () => {
    if (tasaDeCambio) {
      return (cantidadEnPesos / tasaDeCambio).toFixed(2);
    }
    return '';
  };

const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    { id: 1, src: nmax155Image1, alt: 'Nmax Yamaha 155 4rent' },
    { id: 2, src: nmax155Image2, alt: 'Nmax Yamaha 155 4rent' },
    { id: 3, src: nmax155Image3, alt: 'Nmax Yamaha 155 4rent' }
   

  ];
  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };
  const alquilerDataNmax155 = {
    semana: [
      
      { km: '250', cop: '490.000', usd: '130' },
      { km: '700', cop: '650.000', usd: '175' },
      { km: 'Ilimitado', cop: '990.000', usd: '265' }
    ],
    quincena: [
      { km: '1.000', cop: '1\'190.000', usd: '320' },
      { km: '1.500', cop: '1’390.000', usd: '375' },
      { km: 'Ilimitado', cop: '1’690.000', usd: '440' }
    ],
    mes: [
      { km: '1.000', cop: '1’790.000', usd: '480' },
      { km: '2.000', cop: '2’090.000', usd: '560' },
      { km: 'Ilimitado', cop: '2’790.000', usd: '750' }
    ],
    dias: [
      { desc: '24 horas', cop: '179.000', usd: '49' },
      { desc: '2 - 11 días', cop: '139.000', usd: '38' },
      { desc: '12 - 23 días', cop: '109.000', usd: '30' },
      { desc: '24 días o más', cop: '99.000', usd: '27' }
    ],
    kmAdicional: '900',
    horaAdicional: '25.000',
    deposito: '1\'300.000',
  };

  return (
    <section className="max-w-full mx-auto mt-8">
    <div>

    <div className="header-container" style={{ height: '76vh' }}>
  <div className="header-image" style={{ backgroundImage: `url(${fz250_hero})`, backgroundPosition: 'center', backgroundSize: 'cover', width: '100%' }}></div>
  <div className="overlay"></div>
  <div className="header-content">


    <p className="title" id='nmax'>Nmax 155</p>
    <div className="subtitle">
        <h1>Alquiler de Yamaha Nmax 155 en Bogotá <span>4rent</span></h1>
        Vive la mejor experiencia al conducir una moto en Colombia.
        </div>


    <div className="button-container">
      <Link to="/motocicletas" className="button-outline_o">
        <p>Reserva Hora</p>
      </Link>
    </div>
  </div>
  <div className="additional-content"></div>
</div>



   
<div className='herotitulo'>
<h2 className="titulodehero">NMAX 155</h2>
</div>



    <div className="carousel-container">
    <div className="max-w-3xl mx-auto mt-8">
      <div className="carousel-wrapper">
        <div className="carousel">
          <img
            src={images[currentImageIndex].src}
            alt={images[currentImageIndex].alt}
            className="carousel-item"
          />
          <div className="carousel-buttons">
            <button className="button-outline button-prev" onClick={handlePrev}>Anterior</button>
            <button className="button-outline button-next" onClick={handleNext}>Siguiente</button>
          </div>
        </div>
      </div>
    </div>
  </div>



 {/* tabla */}
 <table className="table-container">
        <tbody>
          <tr>
            <td colSpan="3">
              <h2 className="section-title">Planes y Tarifas</h2>
            </td>
          </tr>
        </tbody>
      </table>

      <div className='button-container'>
        <div className="button-text">
          <h2>Alquiler por Días</h2>
        </div>
      </div>

      <table className="data-table">
        <tbody>
          <tr>
            <th>Número de Días</th>
            <th>Tarifa COP</th>
            <th>Tarifa USD</th>
          </tr>
          <tr>
            <td>{alquilerDataNmax155.dias[0].desc}</td>
            <td>${alquilerDataNmax155.dias[0].cop}<span className="highlight"> por día</span></td>
            <td>${alquilerDataNmax155.dias[0].usd}</td>
          </tr>
          <tr>
            <td>{alquilerDataNmax155.dias[1].desc}</td>
            <td>${alquilerDataNmax155.dias[1].cop}<span className="highlight"> por día</span></td>
            <td>${alquilerDataNmax155.dias[1].usd}</td>
          </tr>
          <tr>
            <td>{alquilerDataNmax155.dias[2].desc}</td>
            <td>${alquilerDataNmax155.dias[2].cop}<span className="highlight"> por día</span></td>
            <td>${alquilerDataNmax155.dias[2].usd}</td>
          </tr>
          <tr>
            <td>{alquilerDataNmax155.dias[3].desc}</td>
            <td>${alquilerDataNmax155.dias[3].cop}<span className="highlight"> por día</span></td>
            <td>${alquilerDataNmax155.dias[3].usd}</td>
          </tr>
        </tbody>
      </table>

      <div className='button-container'>
        <div className="button-text">
          <h2>Planes Semanales - 7 días</h2>
        </div>
      </div>

      <table className="data-table">
        <tbody>
          <tr>
            <th>Kilómetros</th>
            <th>Tarifa COP</th>
            <th>Tarifa USD</th>
          </tr>
          <tr>
            <td>Plan 250 Km</td>
            <td>${alquilerDataNmax155.semana[0].cop}</td>
            <td>${alquilerDataNmax155.semana[0].usd}</td>
          </tr>
          <tr>
            <td>Plan 700 Km</td>
            <td>${alquilerDataNmax155.semana[1].cop}</td>
            <td>${alquilerDataNmax155.semana[1].usd}</td>
          </tr>
          <tr>
            <td>Plan Ilimitado</td>
            <td>${alquilerDataNmax155.semana[2].cop}</td>
            <td>${alquilerDataNmax155.semana[2].usd}</td>
          </tr>
        </tbody>
      </table>

      <div className='button-container'>
        <div className="button-text">
          <h2>Planes Quincenales - 15 días</h2>
        </div>
      </div>

      <table className="data-table">
        <tbody>
          <tr>
            <th>Kilómetros</th>
            <th>Tarifa COP</th>
            <th>Tarifa USD</th>
          </tr>
          <tr>
            <td>Plan 1,000 Km</td>
            <td>${alquilerDataNmax155.quincena[0].cop}</td>
            <td>${alquilerDataNmax155.quincena[0].usd}</td>
          </tr>
          <tr>
            <td>Plan 1,500 Km</td>
            <td>${alquilerDataNmax155.quincena[1].cop}</td>
            <td>${alquilerDataNmax155.quincena[1].usd}</td>
          </tr>
          <tr>
            <td>Plan Ilimitado</td>
            <td>${alquilerDataNmax155.quincena[2].cop}</td>
            <td>${alquilerDataNmax155.quincena[2].usd}</td>
          </tr>
        </tbody>
      </table>

      <div className='button-container'>
        <div className="button-text">
          <h2>Planes Mensuales - 30 días</h2>
        </div>
      </div>

      <table className="data-table">
        <tbody>
          <tr>
            <th>Kilómetros</th>
            <th>Tarifa COP</th>
            <th>Tarifa USD</th>
          </tr>
          <tr>
            <td>Plan 1,000 Km</td>
            <td>${alquilerDataNmax155.mes[0].cop}</td>
            <td>${alquilerDataNmax155.mes[0].usd}</td>
          </tr>
          <tr>
            <td>Plan 2,000 Km</td>
            <td>${alquilerDataNmax155.mes[1].cop}</td>
            <td>${alquilerDataNmax155.mes[1].usd}</td>
          </tr>
          <tr>
            <td>Plan Ilimitado</td>
            <td>${alquilerDataNmax155.mes[2].cop}</td>
            <td>${alquilerDataNmax155.mes[2].usd}</td>
          </tr>
        </tbody>
      </table>
      {/* tabla */}







  




  <div className="additional-container">
  <h2 className="additional-title">Adicionales</h2>
  <table className="additional-table">
    <tbody>
      <tr>
        <td className="additional-cell">
          <p className="additional-item-title">Kilómetro adicional:</p>
        </td>
        <td className="additional-cell">
          <p className="additional-item-price">$<span>{alquilerDataNmax155.kmAdicional}</span> COP</p>
        </td>
      </tr>
      <tr>
        <td className="additional-cell">
          <p className="additional-item-title">Hora adicional:</p>
        </td>
        <td className="additional-cell">
          <p className="additional-item-price">$<span>{alquilerDataNmax155.horaAdicional}</span> COP</p>
        </td>
      </tr>
      <tr>
        <td className="additional-cell">
          <p className="additional-item-title">Depósito / fianza:</p>
        </td>
        <td className="additional-cell">
          <p className="additional-item-price">$<span>{alquilerDataNmax155.deposito}</span> COP</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>



  <div style={{ 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  height: '20vh',
}}>
  <ButtonWhatsApp style={{ 
    backgroundColor: '#28A745', 
    color: '#fff', 
    border: '1px solid #218838', 
    padding: '12px 24px', 
    borderRadius: '8px', 
    fontSize: '18px', 
    fontWeight: '600', 
    textTransform: 'uppercase', 
    letterSpacing: '1px',
    textDecoration: 'none',
    display: 'inline-block',
    cursor: 'pointer',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
    transition: 'background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease', // Añade la transición de sombra
  }} phoneNumberR={phoneNumber} message={message} />
</div>











<div className="banner">
<div className="banner-content">
<h3 className="banner-title  text-3xl font-bold mb-4 text-center">Cobertura Completa para tu Tranquilidad</h3>
</div>
</div>
</div>
  {convertirADolares()}
        <TarifasComponent />
        <div className="banner">
<div className="banner-content">
<h3 className="banner-title text-3xl font-bold mb-4 text-center">Especificaciones Técnicas</h3>
</div>
</div>
        <div className="max-w-4xl mx-auto mt-8">
  <table className="w-full my-8">
    <tbody className="bg-gray-10">
      <tr>
        <td className="border-b border-gray-300 py-2"><TbCircuitMotor /></td>
        <th className="border-b border-gray-300 py-2">Motor:</th>
        <td className="border-b border-gray-300 py-2">Blue Core 4 tiempos, monocilíndrico, refrigerado por líquido</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><TbCylinderPlus /></td>
        <th className="border-b border-gray-300 py-2">Cilindrada:</th>
        <td className="border-b border-gray-300 py-2">155 cc</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><AiOutlineSisternode /></td>
        <th className="border-b border-gray-300 py-2">Alimentación:</th>
        <td className="border-b border-gray-300 py-2">Inyección electrónica de combustible (EFI)</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><SiVirginmedia /></td>
        <th className="border-b border-gray-300 py-2">Diámetro x carrera:</th>
        <td className="border-b border-gray-300 py-2">58.0 mm x 58.7 mm</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"> <GiHorseHead /></td>
        <th className="border-b border-gray-300 py-2">Potencia:</th>
        <td className="border-b border-gray-300 py-2">11 kW (15 CV) a 8,000 rpm</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><CgChanel /></td>
        <th className="border-b border-gray-300 py-2">Torque Máximo:</th>
        <td className="border-b border-gray-300 py-2">14.4 Nm a 6,000 rpm</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><SlEnergy /></td>
        <th className="border-b border-gray-300 py-2">Arranque:</th>
        <td className="border-b border-gray-300 py-2">Eléctrico</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><GiGasPump /></td>
        <th className="border-b border-gray-300 py-2">Capacidad de combustible:</th>
        <td className="border-b border-gray-300 py-2">6.6 L</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><GiSpeedometer /></td>
        <th className="border-b border-gray-300 py-2">Transmisión:</th>
        <td className="border-b border-gray-300 py-2">Automática CVT</td>
      </tr>
    </tbody>
  </table>
</div>



<div className="banner">
<div className="banner-content">
<h3 className="banner-title text-3xl font-bold mb-4 text-center">Lo incluido dentro de las tarifas</h3>
</div>
</div>
<div className="container mx-auto">
      <table className="table-auto border-collapse border">
        <thead>
          <tr>
            <th className=" text-start px-4 py-2 text-xl"></th>
            <th className="  px-4 py-2 text-xl ">Cantidad</th>
          </tr>
        </thead>
        <tbody>
  <tr>
    <td className="table-cell-icon">
      <GiFullMotorcycleHelmet className="icon" /> Cascos
    </td>
    <td className="table-cell">2</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <GiLifeJacket className="icon" /> Chalecos reflectivos
    </td>
    <td className="table-cell">2</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <FaBoxArchive className="icon" /> Baúl de 40 Litros (Trunk)
    </td>
    <td className="table-cell">1</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <IoPhonePortraitSharp className="icon" /> Soporte para celular en aluminio
    </td>
    <td className="table-cell">1</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <RiSecurePaymentFill className="icon" /> Seguro contra robo (Deducible del 15%)
    </td>
    <td className="table-cell">✔</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <MdPermPhoneMsg className="icon" /> Asistencia Telefónica 24/7 (Emergencias)
    </td>
    <td className="table-cell">✔</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <GiColombia className="icon" /> Mantenimientos Incluidos a nivel Nacional
    </td>
    <td className="table-cell">✔</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <RiSecurePaymentFill className="icon" /> Seguro contra pérdidas totales (Deducible del 15%)
    </td>
    <td className="table-cell">✔</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <TbCarCrane className="icon" /> Grúa 24/7 a nivel nacional (Problemas mecánicos o accidente)
    </td>
    <td className="table-cell">✔</td>
  </tr>
</tbody>


      </table>
    </div>

    <div className="r6j8-qlsj-container">
  <h2 className="r6j8-qlsj-title">¡Listo para desatar la emoción sobre dos ruedas?</h2>
  <div className="r6j8-qlsj-grid">
    <div className="r6j8-qlsj-button-container">
      <a href="/contacto" className="r6j8-qlsj-button">¡Comunícate por WhatsApp!</a>
    </div>
    <div className="r6j8-qlsj-text-container">
      <p className="r6j8-qlsj-text">Descubre la Yamaha NMAX 155, un scooter que combina estilo urbano y rendimiento excepcional. Con su motor potente y diseño aerodinámico, conquista las calles de la ciudad con estilo y comodidad. Prepárate para una experiencia de conducción única y emocionante.</p>
    </div>
  </div>
</div> 
      </section>
  )
}

export default Nmax