
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import FloatingWhatsApp from './components/FloatingWhatsApp';
import Home from './Pages/Home';
import Contacto from './Pages/Contacto';
import Nosotros from './Pages/Nosotros';
import Experience from './Pages/Experience';
import Automaticas from './Pages/Automaticas';
import Manuales from './Pages/Manuales'; 
import Seguros from './Pages/Seguros';
import Rentar from './Pages/Rentar';
import Reservation from './Pages/Reservation';
import Requisitos4Rent from './Pages/Requisitos4Rent';
import Condiciones4Rent from './Pages/Condiciones4Rent';
import Blog from './Pages/Blog';  
import NotFoundPage from './Pages/NotFoundPage';
import VariedadMotos from './Pages/VariedadMotos';
import Asesores from './Pages/Asesores';
import Motocicletas from './Pages/Motocicletas';
import Nmax from './Pages/motos_A/Nmax';
import Fz250 from './Pages/motos_A/Fz250';
import Dominar400 from './Pages/motos_A/Dominar400';
import Xre300 from './Pages/motos_A/Xre300';
import Xtz150 from './Pages/motos_A/Xtz150';
import Xpulse200 from './Pages/motos_A/Xpulse200';
import Girardod from './Pages/Spaces/Girardod';
import Parques from './Pages/Spaces/Parques';
import Laguna from './Pages/Spaces/Laguna';
import Chiflón from './Pages/Spaces/Chiflón';
import Centro from './Pages/Spaces/Centro';
import Jardin from './Pages/Spaces/Jardin';
import Ruiz from './Pages/Spaces/Ruiz';
import Villa from './Pages/Spaces/Villa';
import Caño from './Pages/Spaces/Caño';
function App() {
  return (
    <Router >
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element ={<Home phoneNumber="+573154573482"/>} />
          <Route path="/motocicletas" element ={<Motocicletas phoneNumbertow="3154573482"/>} />
          <Route path="/contacto" element ={<Contacto phoneNumberone="3154573482"/>} />
          <Route path="/nosotros" element ={<Nosotros />} />
          <Route path="/manuales" element ={<Manuales />} />
          <Route path="/automaticas" element ={<Automaticas />} />
          <Route path="/experience" element ={<Experience />} />
          <Route path="/seguros" element ={<Seguros />} />
          <Route path="/rentar" element ={<Rentar/>} />
          <Route path="/nmax" element ={<Nmax/>} />
          <Route path="/fz250" element ={<Fz250/>} />
          <Route path="/xtz150" element ={<Xtz150/>} />
          <Route path="/xpulse200" element ={<Xpulse200/>} />
          <Route path="/dominar400" element ={<Dominar400/>} />
          <Route path="/xre300" element ={<Xre300 />} />
          <Route path="/reservation" element ={<Reservation />} />
          <Route path="/condiciones4rent" element ={<Condiciones4Rent />} />
          <Route path="/requisitos4rent" element ={<Requisitos4Rent />} />
          <Route path="/blog" element ={<Blog />} />
          <Route path="/variedadMotos" element ={<VariedadMotos />} />
          <Route path="/asesores" element ={<Asesores />} /> 
          <Route path="*" element ={<NotFoundPage />} />
          <Route path="/girardod" element ={<Girardod />} /> 
          <Route path="/parques" element ={<Parques />} /> 
          <Route path="/laguna" element ={<Laguna />} /> 
          <Route path="/chiflón" element ={<Chiflón />} />
          <Route path="/centro" element ={<Centro />} />
          <Route path="/jardin" element ={<Jardin />} />
          <Route path="/ruiz" element ={<Ruiz />} />
          <Route path="/caño" element ={<Caño/>} />
          <Route path="/villa" element ={<Villa/>} />
        </Routes>
        <FloatingWhatsApp phoneNumber="3154573482" />
        <Footer />
      </div>
    </Router>
  );
}
export default App;
