
import img3 from "../../assets/Gitardot.jpg";

function Girardod() {
  return (
    <div>
      <style>
        {`
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }
          header {
            background-color: #333;
            color: #ffff;
            padding: 20px;
            margin-top:50px;
            text-align: center;
          }
          main {
            padding: 20px;
            text-align: center;
          }
          h1 {
            color: #333;
          }
          p {
            color: #666;
            line-height: 1.6; /* Añadido: Mejora la legibilidad del texto */
          }
          .image-container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }
          .image-container img {
            width: 300px;
            height: auto;
            margin: 0 10px;
            border-radius: 8px; /* Añadido: Un pequeño detalle estético */
          }
        `}
      </style>
      <header>
        <h1 className="text-white">Viaja a Girardot con una moto rentada en 4rent Bogotá.</h1>
      </header>
      <main>
        <h2>Descubre las regiones de Colombia, sus paisajes y climas tropicales.</h2>
        <p>
          El Área Metropolitana de Girardot es una región de Colombia que abarca los municipios de Girardot, Flandes y Ricaurte. Esta área, con una población estimada de 184,330 habitantes en 2008, es un importante centro turístico del país, ubicado entre los departamentos de Cundinamarca y Tolima.
        </p>
        <div className="image-container">

          <img src={img3} alt="Imagen 3" />
        </div>
      </main>
    </div>
  );
}

export default Girardod;

