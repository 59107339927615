import  { useState } from "react";

const Reservation = () => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [licensePermit, setLicensePermit] = useState("");
  
  const [documentType, setDocumentType] = useState("");
  const [motorcycleChoice, setMotorcycleChoice] = useState("");
  const [estimatedPickUpDate, setEstimatedPickUpDate] = useState("");
  const [estimatedReturnDate, setEstimatedReturnDate] = useState("");
  const [whatsAppTelegram, setWhatsAppTelegram] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  const [helmetSize1, setHelmetSize1] = useState("");
  const [helmetSize2, setHelmetSize2] = useState("");
  const [comments, setComments] = useState("");
  const [deliveryService, setDeliveryService] = useState(false);
  const [returnService, setReturnService] = useState(false);
  const [acceptance, setAcceptance] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes agregar la lógica para enviar la información de reserva al servidor
    console.log("Nombre:", name);
    console.log("Apellido:", lastName);
    console.log("Correo electrónico:", email);
    console.log("Número de teléfono:", phoneNumber);
    console.log("Fecha de inicio:", startDate);
    console.log("Fecha de finalización:", endDate);
      {/* Aquí puedes agregar más campos de formulario según sea necesario */}




    console.log("Licencia/Permiso:", licensePermit);
    console.log("Tipo de documento:", documentType);
          {/* Aquí puedes agregar más campos de formulario según sea necesario */}
    console.log("Moto elegida:", motorcycleChoice);
    console.log("Fecha estimada de recogida:", estimatedPickUpDate);
    console.log("Fecha estimada de devolución:", estimatedReturnDate);
    console.log("WhatsApp o Telegram:", whatsAppTelegram);
    console.log("Contacto de emergencia:", emergencyContact);
    console.log("Tamaño del casco 1:", helmetSize1);
    console.log("Tamaño del casco 2:", helmetSize2);
    console.log("Comentarios:", comments);
    console.log("Servicio de entrega:", deliveryService);
    console.log("Servicio de devolución:", returnService);
    console.log("Aceptación de procesamiento de datos personales:", acceptance);
    // Puedes enviar los datos a través de una solicitud HTTP aquí
    // Por ejemplo, utilizando fetch() o Axios
  };

  return (
    <div className="container mx-auto mt-10">
    <h2 className="text-3xl font-semibold mb-6">Reserva tu moto</h2>
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
              {/* Aquí puedes agregar más campos de formulario según sea necesario */}
      <div className="mb-4">
        <label htmlFor="name" className="block mb-1">Nombre:</label>
        <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className="w-full px-4 py-2 border rounded-md" required />
      </div>
      <div className="mb-4">
        <label htmlFor="last-name" className="block mb-1">Apellido:</label>
        <input type="text" id="last-name" value={lastName} onChange={(e) => setLastName(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block mb-1">Correo electrónico:</label>
        <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full px-4 py-2 border rounded-md" required />
      </div>
      <div className="mb-4">
        <label htmlFor="phone" className="block mb-1">Número de teléfono:</label>
        <input type="tel" id="phone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="w-full px-4 py-2 border rounded-md" required />
      </div>
      <div className="mb-4">
        <label htmlFor="start-date" className="block mb-1">Fecha de inicio:</label>
        <input type="date" id="start-date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="w-full px-4 py-2 border rounded-md" required />
      </div>
      <div className="mb-6">
        <label htmlFor="end-date" className="block mb-1">Fecha de finalización:</label>
        <input type="date" id="end-date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="w-full px-4 py-2 border rounded-md" required />
      </div>

      {/* Aquí puedes agregar más campos de formulario según sea necesario */}
      <div className="mb-4">
        <label htmlFor="license-permit" className="block mb-1">Licencia/Permiso:</label>
        <input type="text" id="license-permit" value={licensePermit} onChange={(e) => setLicensePermit(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
      </div>
    
      <div className="mb-4">
        <label htmlFor="document-type" className="block mb-1">Tipo de documento:</label>
        <input type="text" id="document-type" value={documentType} onChange={(e) => setDocumentType(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
      </div>
  {/* Aquí puedes agregar más campos de formulario según sea necesario */}
      <div className="mb-4">
  <label htmlFor="motorcycle-choice" className="block mb-1">Moto elegida:</label>
  <input type="text" id="motorcycle-choice" value={motorcycleChoice} onChange={(e) => setMotorcycleChoice(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
</div>
<div className="mb-4">
  <label htmlFor="estimated-pickup-date" className="block mb-1">Fecha estimada de recogida:</label>
  <input type="text" id="estimated-pickup-date" value={estimatedPickUpDate} onChange={(e) => setEstimatedPickUpDate(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
</div>
<div className="mb-4">
  <label htmlFor="estimated-return-date" className="block mb-1">Fecha estimada de devolución:</label>
  <input type="text" id="estimated-return-date" value={estimatedReturnDate} onChange={(e) => setEstimatedReturnDate(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
</div>
<div className="mb-4">
  <label htmlFor="whatsapp-telegram" className="block mb-1">WhatsApp o Telegram:</label>
  <input type="text" id="whatsapp-telegram" value={whatsAppTelegram} onChange={(e) => setWhatsAppTelegram(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
</div>
<div className="mb-4">
  <label htmlFor="emergency-contact" className="block mb-1">Contacto de emergencia:</label>
  <input type="text" id="emergency-contact" value={emergencyContact} onChange={(e) => setEmergencyContact(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
</div>
<div className="mb-4">
  <label htmlFor="helmet-size1" className="block mb-1">Tamaño del casco 1:</label>
  <input type="text" id="helmet-size1" value={helmetSize1} onChange={(e) => setHelmetSize1(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
</div>
<div className="mb-4">
  <label htmlFor="helmet-size2" className="block mb-1">Tamaño del casco 2:</label>
  <input type="text" id="helmet-size2" value={helmetSize2} onChange={(e) => setHelmetSize2(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
</div>
<div className="mb-4">
  <label htmlFor="comments" className="block mb-1">Comentarios:</label>
  <textarea id="comments" value={comments} onChange={(e) => setComments(e.target.value)} className="w-full px-4 py-2 border rounded-md"></textarea>
</div>
<div className="mb-4">
  <label htmlFor="delivery-service" className="block mb-1">Servicio de entrega:</label>
  <input type="text" id="delivery-service" value={deliveryService} onChange={(e) => setDeliveryService(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
</div>
<div className="mb-4">
  <label htmlFor="return-service" className="block mb-1">Servicio de devolución:</label>
  <input type="text" id="return-service" value={returnService} onChange={(e) => setReturnService(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
</div>
<div className="mb-4">
  <label htmlFor="acceptance" className="block mb-1">Aceptación de procesamiento de datos personales:</label>
  <input type="text" id="acceptance" value={acceptance} onChange={(e) => setAcceptance(e.target.value)} className="w-full px-4 py-2 border rounded-md" />
</div>





      <button type="submit" className="w-full bg-primary text-white py-2 rounded-md hover:bg-primary-dark transition duration-300">Reservar ahora</button>
    </form>
    <div className="mb-4 p-4 bg-gray-100 rounded-md">
  <p className="text-lg text-gray-700">Recuerda traer el pasaporte o cédula y la licencia de conducción en físico, y leer los requisitos y condiciones. ¡Muchas gracias!</p>
</div>



  </div>
  
  
  );
};

export default Reservation;
