import './Asesores.css';

function Asesores() {
  return (
    <div className="container-asesores text-gray-800 mb-4" id='asesores'>
      <h1 className="titulo-asesores text-3xl sm:text-4xl font-bold mb-4">Nuestros Asesores</h1>
      <p className="mensaje-ayuda">Estamos aquí para ayudarte con todas tus necesidades de alquiler de motocicletas.</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div className="card-asesor bg-white shadow-lg rounded-lg overflow-hidden">
          <img src="ruta-a-la-imagen" alt="Foto de José" className="w-full h-auto" />
          <div className="p-4">
            <h2 className="text-xl font-bold mb-2">José</h2>
            <p className="text-gray-700 mb-2">Especialidad: Motocicletas</p>
            <p className="text-gray-700">Teléfono: 315 4573482</p>
          </div>
        </div>
      
      </div>
    </div>
  );
}

export default Asesores;
