import { useState, useEffect } from 'react';
import "./SameMotos.css";
import TarifasComponent from './TarifasComponent';
import axios from 'axios';
import ButtonWhatsApp from "./ButtonWhatsApp";
import messages from './messages'; 
import { TbCircuitMotor, TbCylinderPlus} from "react-icons/tb";
import { AiOutlineSisternode } from "react-icons/ai";
import { SlEnergy } from "react-icons/sl";
import { GiGasPump, GiHorseHead ,GiSpeedometer , GiFullMotorcycleHelmet, GiLifeJacket } from "react-icons/gi";
import { CgChanel } from "react-icons/cg";
import { SiVirginmedia } from "react-icons/si";
import { FaBoxArchive } from "react-icons/fa6";
import { IoPhonePortraitSharp } from "react-icons/io5";
import { RiSecurePaymentFill } from "react-icons/ri";
import { MdPermPhoneMsg } from "react-icons/md";
import { GiColombia } from "react-icons/gi";
import { TbCarCrane } from "react-icons/tb";
import image1 from '../../assets/Dominar400_01.png';
import image2 from '../../assets/Dominar400_02.png';
import image3 from '../../assets/Dominar400_03.png';
import image4 from '../../assets/Dominar400_04.png';
import image5 from '../../assets/Dominar400_05.png';
import image6 from '../../assets/Dominar400_06.png';
import image7 from '../../assets/Dominar400_07.png';
import image8 from '../../assets/Dominar400_08.png';
import image9 from '../../assets/Dominar400_09.png';
import image10 from '../../assets/Dominar400_10.png';
import image11 from '../../assets/Dominar400_11.png';
import image12 from '../../assets/Dominar400_12.png';
import image13 from '../../assets/Dominar400_13.png';
import fz250_hero from "../../assets/Dominar400_08.png"
const Dominar400 = () => {
  const phoneNumber = "3154573482";
  const message = messages.page1; 
  const [tasaDeCambio, setTasaDeCambio] = useState(null);
  const cantidadEnPesos = 49999;
  useEffect(() => {
    const fetchTasaDeCambio = async () => {
      try {
        const response = await axios.get('/obtener_tasa_de_cambio.php');
        setTasaDeCambio(response.data.conversion_rates.USD);
      } catch (error) {
        console.error('Error al obtener la tasa de cambio:', error);
      }
   };
    fetchTasaDeCambio();
  }, []);
  const convertirADolares = () => {
    if (tasaDeCambio) {
      return (cantidadEnPesos / tasaDeCambio).toFixed(2);
    }
    return '';
  };
const [currentImageIndex, setCurrentImageIndex] = useState(0);
const images = [
    { id: 1, src: image1, alt: 'Dominar 400 4rent' },
    { id: 2, src: image2, alt: 'Dominar 400 4rent' },
    { id: 3, src: image3, alt: 'Dominar 400 4rent' },
    { id: 4, src: image4, alt: 'Dominar 400 4rent' },
    { id: 5, src: image5, alt: 'Dominar 400 4rent' },
    { id: 6, src: image6, alt: 'Dominar 400 4rent' },
    { id: 7, src: image7, alt: 'Dominar 400 4rent' },
    { id: 8, src: image8, alt: 'Dominar 400 4rent' },
    { id: 9, src: image9, alt: 'Dominar 400 4rent' },
    { id: 10, src: image10, alt: 'Dominar 400 4rent' },
    { id: 11, src: image11, alt: 'Dominar 400 4rent' },
    { id: 12, src: image12, alt: 'Dominar 400 4rent' },
    { id: 13, src: image13, alt: 'Dominar 400 4rent' }
];
  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };
  const alquilerDataDominar = {
    semana: [
      { km: '250', cop: '690.000', usd: '185' },
      { km: '700', cop: '990.000', usd: '265' },
      { km: 'Ilimitado', cop: '1\'390.000', usd: '370' }
    ],
    quincena: [
      { km: '1.000', cop: '1\'690.000', usd: '450' },
      { km: '1.500', cop: '1\'990.000', usd: '530' },
      { km: 'Ilimitado', cop: '2\'590.000', usd: '690' }
    ],
    mes: [
      { km: '1.000', cop: '2\'590.000', usd: '690' },
      { km: '2.000', cop: '2\'990.000', usd: '795' },
      { km: 'Ilimitado', cop: '3\'990.000', usd: "1.065" }
    ],
    dias: [
      { desc: '24 horas', cop: '249.000' , usd: '68'},
      { desc: '2 - 11 días', cop: '199.000', usd: '54' },
      { desc: '12 - 23 días', cop: '149.000', usd: '41' },
      { desc: '24 días o más', cop: '129.000', usd: '35' }
    ],
    kmAdicional: '1.100',
    horaAdicional: '30.000',
    deposito: '1\'500.000',
  };
  return (
    <section id='dominar'  className="max-w-full mx-auto mt-8">
  <div>

<div className="header-container" style={{ height: '76vh' }}>
    <div className="header-image" style={{ backgroundImage:  `url(${fz250_hero})`, backgroundPosition: 'center', backgroundSize: 'cover', width: '100%' }}></div>
    <div className="overlay"></div>
    <div className="header-content">
        <p className="title" id='dominar400' >Dominar 400</p>
        <div className="subtitle">
            <h1>Alquiler de Dominar 400 abs en Bogotá <span>4rent</span></h1>
            <p> Vive la mejor experiencia al conducir una moto en Colombia.</p>
        </div>
        <div className="button-container">
            <a href="/motocicletas" className="button-outline_o">
                <p>Reserva Hora</p>
            </a>
        </div>
    </div>
    <div className="additional-content"></div>
</div>

    <div className="carousel-container">
  <div className="max-w-3xl mx-auto mt-8">
   
    <div className='herotitulo'>
<h2 className="titulodehero">DOMINAR 400 ABS</h2>
</div>


    <div className="carousel-wrapper">
      <div className="carousel">
        <img
          src={images[currentImageIndex].src}
          alt={images[currentImageIndex].alt}
          className="carousel-item"
        />
        <div className="carousel-buttons">
          <button className="button-outline button-prev" onClick={handlePrev}>Anterior</button>
          <button className="button-outline button-next" onClick={handleNext}>Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</div>



 {/* tabla */}
 <table className="table-container">
        <tbody>
          <tr>
            <td colSpan="3">
              <h2 className="section-title">Planes y Tarifas</h2>
            </td>
          </tr>
        </tbody>
      </table>

      <div className='button-container'>
        <div className="button-text">
          <h2>Alquiler por Días</h2>
        </div>
      </div>

      <table className="data-table">
        <tbody>
          <tr>
            <th>Número de Días</th>
            <th>Tarifa COP</th>
            <th>Tarifa USD</th>
          </tr>
          <tr>
            <td>{alquilerDataDominar.dias[0].desc}</td>
            <td>${alquilerDataDominar.dias[0].cop}<span className="highlight"> por día</span></td>
            <td>${alquilerDataDominar.dias[0].usd}</td>
          </tr>
          <tr>
            <td>{alquilerDataDominar.dias[1].desc}</td>
            <td>${alquilerDataDominar.dias[1].cop}<span className="highlight"> por día</span></td>
            <td>${alquilerDataDominar.dias[1].usd}</td>
          </tr>
          <tr>
            <td>{alquilerDataDominar.dias[2].desc}</td>
            <td>${alquilerDataDominar.dias[2].cop}<span className="highlight"> por día</span></td>
            <td>${alquilerDataDominar.dias[2].usd}</td>
          </tr>
          <tr>
            <td>{alquilerDataDominar.dias[3].desc}</td>
            <td>${alquilerDataDominar.dias[3].cop}<span className="highlight"> por día</span></td>
            <td>${alquilerDataDominar.dias[3].usd}</td>
          </tr>
        </tbody>
      </table>

      <div className='button-container'>
        <div className="button-text">
          <h2>Planes Semanales - 7 días</h2>
        </div>
      </div>

      <table className="data-table">
        <tbody>
          <tr>
            <th>Kilómetros</th>
            <th>Tarifa COP</th>
            <th>Tarifa USD</th>
          </tr>
          <tr>
            <td>Plan 250 Km</td>
            <td>${alquilerDataDominar.semana[0].cop}</td>
            <td>${alquilerDataDominar.semana[0].usd}</td>
          </tr>
          <tr>
            <td>Plan 700 Km</td>
            <td>${alquilerDataDominar.semana[1].cop}</td>
            <td>${alquilerDataDominar.semana[1].usd}</td>
          </tr>
          <tr>
            <td>Plan Ilimitado</td>
            <td>${alquilerDataDominar.semana[2].cop}</td>
            <td>${alquilerDataDominar.semana[2].usd}</td>
          </tr>
        </tbody>
      </table>

      <div className='button-container'>
        <div className="button-text">
          <h2>Planes Quincenales - 15 días</h2>
        </div>
      </div>

      <table className="data-table">
        <tbody>
          <tr>
            <th>Kilómetros</th>
            <th>Tarifa COP</th>
            <th>Tarifa USD</th>
          </tr>
          <tr>
            <td>Plan 1,000 Km</td>
            <td>${alquilerDataDominar.quincena[0].cop}</td>
            <td>${alquilerDataDominar.quincena[0].usd}</td>
          </tr>
          <tr>
            <td>Plan 1,500 Km</td>
            <td>${alquilerDataDominar.quincena[1].cop}</td>
            <td>${alquilerDataDominar.quincena[1].usd}</td>
          </tr>
          <tr>
            <td>Plan Ilimitado</td>
            <td>${alquilerDataDominar.quincena[2].cop}</td>
            <td>${alquilerDataDominar.quincena[2].usd}</td>
          </tr>
        </tbody>
      </table>

      <div className='button-container'>
        <div className="button-text">
          <h2>Planes Mensuales - 30 días</h2>
        </div>
      </div>

      <table className="data-table">
        <tbody>
          <tr>
            <th>Kilómetros</th>
            <th>Tarifa COP</th>
            <th>Tarifa USD</th>
          </tr>
          <tr>
            <td>Plan 1,000 Km</td>
            <td>${alquilerDataDominar.mes[0].cop}</td>
            <td>${alquilerDataDominar.mes[0].usd}</td>
          </tr>
          <tr>
            <td>Plan 2,000 Km</td>
            <td>${alquilerDataDominar.mes[1].cop}</td>
            <td>${alquilerDataDominar.mes[1].usd}</td>
          </tr>
          <tr>
            <td>Plan Ilimitado</td>
            <td>${alquilerDataDominar.mes[2].cop}</td>
            <td>${alquilerDataDominar.mes[2].usd}</td>
          </tr>
        </tbody>
      </table>
      {/* tabla */}




      <div className="additional-container">
  <h2 className="additional-title">Adicionales</h2>
  <table className="additional-table">
    <tbody>
      <tr>
        <td className="additional-cell">
          <p className="additional-item-title">Kilómetro adicional:</p>
        </td>
        <td className="additional-cell">
          <p className="additional-item-price">$<span>{alquilerDataDominar.kmAdicional}</span> COP</p>
        </td>
      </tr>
      <tr>
        <td className="additional-cell">
          <p className="additional-item-title">Hora adicional:</p>
        </td>
        <td className="additional-cell">
          <p className="additional-item-price">$<span>{alquilerDataDominar.horaAdicional}</span> COP</p>
        </td>
      </tr>
      <tr>
        <td className="additional-cell">
          <p className="additional-item-title">Depósito / fianza:</p>
        </td>
        <td className="additional-cell">
          <p className="additional-item-price">$<span>{alquilerDataDominar.deposito}</span> COP</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>





<div style={{ 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  height: '20vh',
}}>
  <ButtonWhatsApp style={{ 
    backgroundColor: '#28A745', 
    color: '#fff', 
    border: '1px solid #218838', 
    padding: '12px 24px', 
    borderRadius: '8px', 
    fontSize: '18px', 
    fontWeight: '600', 
    textTransform: 'uppercase', 
    letterSpacing: '1px',
    textDecoration: 'none',
    display: 'inline-block',
    cursor: 'pointer',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
    transition: 'background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease', // Añade la transición de sombra
  }} phoneNumberR={phoneNumber} message={message} />
</div>





<div className="banner">
<div className="banner-content">
<h3 className="banner-title  text-3xl font-bold mb-4 text-center">Cobertura Completa para tu Tranquilidad</h3>
</div>
</div>
</div>
{convertirADolares()}
      <TarifasComponent />

      <div className="banner">
<div className="banner-content">
<h3 className="banner-title text-3xl font-bold mb-4 text-center">Especificaciones Técnicas</h3>
</div>
</div>


  <div className="max-w-4xl mx-auto mt-8">
 
  <table className="w-full  my-8">
    <tbody className="bg-gray-10">
      <tr>
        <td className="border-b border-gray-300 py-2"><TbCircuitMotor /></td>
        <th className="border-b border-gray-300 py-2">Motor:</th>
        <td className="border-b border-gray-300 py-2">4 tiempos, monocilíndrico, DOHC, refrigerado por líquido</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><TbCylinderPlus /></td>
        <th className="border-b border-gray-300 py-2">Cilindrada:</th>
        <td className="border-b border-gray-300 py-2">373.3 cc</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><AiOutlineSisternode /></td>
        <th className="border-b border-gray-300 py-2">Alimentación:</th>
        <td className="border-b border-gray-300 py-2">Inyección de combustible (FI)</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><SiVirginmedia /></td>
        <th className="border-b border-gray-300 py-2">Diámetro x carrera:</th>
        <td className="border-b border-gray-300 py-2">89 mm x 60 mm</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"> <GiHorseHead /></td>
        <th className="border-b border-gray-300 py-2">Potencia:</th>
        <td className="border-b border-gray-300 py-2">27.2 kW (35 HP) a 8,000 rpm</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><CgChanel /></td>
        <th className="border-b border-gray-300 py-2">Torque Máximo:</th>
        <td className="border-b border-gray-300 py-2">35 Nm a 6,500 rpm</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><SlEnergy /></td>
        <th className="border-b border-gray-300 py-2">Arranque:</th>
        <td className="border-b border-gray-300 py-2">Eléctrico</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><GiGasPump /></td>
        <th className="border-b border-gray-300 py-2">Capacidad de combustible:</th>
        <td className="border-b border-gray-300 py-2">13 L</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><GiSpeedometer /></td>
        <th className="border-b border-gray-300 py-2">Transmisión:</th>
        <td className="border-b border-gray-300 py-2">Manual, 6 velocidades, cadena</td>
      </tr>
    </tbody>
  </table>
</div>

<div className="banner">
<div className="banner-content">
<h3 className="banner-title text-3xl font-bold mb-4 text-center">Lo incluido dentro de las tarifas</h3>
</div>
</div>
<div className="container mx-auto">

      <table className="table-auto border-collapse border">
        <thead>
          <tr>
            <th className=" text-start px-4 py-2 text-xl"></th>
            <th className="  px-4 py-2 text-xl ">Cantidad</th>
          </tr>
        </thead>
   

        <tbody>
  <tr>
    <td className="table-cell-icon">
      <GiFullMotorcycleHelmet className="icon" /> Cascos
    </td>
    <td className="table-cell">2</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <GiLifeJacket className="icon" /> Chalecos reflectivos
    </td>
    <td className="table-cell">2</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <FaBoxArchive className="icon" /> Baúl de 40 Litros (Trunk)
    </td>
    <td className="table-cell">1</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <IoPhonePortraitSharp className="icon" /> Soporte para celular en aluminio
    </td>
    <td className="table-cell">1</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <RiSecurePaymentFill className="icon" /> Seguro contra robo (Deducible del 15%)
    </td>
    <td className="table-cell">✔</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <MdPermPhoneMsg className="icon" /> Asistencia Telefónica 24/7 (Emergencias)
    </td>
    <td className="table-cell">✔</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <GiColombia className="icon" /> Mantenimientos Incluidos a nivel Nacional
    </td>
    <td className="table-cell">✔</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <RiSecurePaymentFill className="icon" /> Seguro contra pérdidas totales (Deducible del 15%)
    </td>
    <td className="table-cell">✔</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <TbCarCrane className="icon" /> Grúa 24/7 a nivel nacional (Problemas mecánicos o accidente)
    </td>
    <td className="table-cell">✔</td>
  </tr>
</tbody>




      </table>
    </div>
    <div className="r6j8-qlsj-container">
  <h2 className="r6j8-qlsj-title">¡Listo para desatar la emoción sobre dos ruedas?</h2>
  <div className="r6j8-qlsj-grid">
    <div className="r6j8-qlsj-button-container">
      <a href="/contacto" className="r6j8-qlsj-button">¡Comunícate por WhatsApp!</a>
    </div>
    <div className="r6j8-qlsj-text-container">
      <p className="r6j8-qlsj-text">  Conoce la Bajaj Dominar 400, una moto de alto rendimiento que redefine la experiencia de conducción. Equipada con un potente motor y un diseño aerodinámico, la Dominar 400 ofrece un desempeño excepcional tanto en entornos urbanos como en carreteras abiertas. Prepárate para elevar tu experiencia de conducción a un nuevo nivel con la Dominar 400.
         </p>
    </div>
  </div>
</div>
    </section>
  );
};
export default Dominar400;


