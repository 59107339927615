// mongo.js

const images = [
  "https://res.cloudinary.com/drhrtqums/image/upload/v1711143509/Dominar400.png",
  "https://res.cloudinary.com/drhrtqums/image/upload/v1711143508/Fz250.png",
  "https://res.cloudinary.com/drhrtqums/image/upload/v1711143507/Xre300.png",
  // Agrega más imágenes según sea necesario
];

const tarifasIncluyen = [
  "Resguardo frente a robo: 80%",
  "Integración de soporte para teléfono celular",
  "Asistencia permanente: 24 horas al día, 7 días a la semana",
  "Protección total contra incidentes viales",
  "Abarca 2 cascos y 2 chalecos reflectantes",
  "Espacioso compartimento de carga con capacidad de 40 litros",
  "Plan de mantenimiento preventivo incluido",
  "Disponibilidad de servicio de remolque",
];


const tarifasNoIncluyen = [
  "Combustible: No está incluido",
  "Limpieza profesional del vehículo: No está incluida",
  "Cobertura para daños parciales: No está incluida",
];



const restricciones = 'Te recordamos que esta moto no está diseñada para ser utilizada en superficies no pavimentadas o caminos fuera de carretera. El uso indebido podría ocasionar gastos adicionales.';

export { images,tarifasIncluyen, tarifasNoIncluyen, restricciones }; 
