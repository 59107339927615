
import './Blog.css'; // Importa el archivo de estilos CSS

function Blog() {
  return (
    <section id='blog' className="blog">
      <main>

      <div className="title" style={{ marginTop: '34px' }}>
        <h1>Blog 4rent</h1>
      </div>


        <article>
          <h2>10 Lugares Increíbles para Visitar</h2>
          <p>Descubre los mejores destinos para tus próximas vacaciones...</p>
          <a href="">Leer más</a>
        </article>

        <article>
          <h2>Consejos para Viajar con un Presupuesto Ajustado</h2>
          <p>Aprende cómo disfrutar de tus viajes sin gastar de más...</p>
          <a href="">Leer más</a>
        </article>

        <article>
          <h2>Guía Completa para Empacar de Forma Eficiente</h2>
          <p>Consejos y trucos para hacer tu equipaje de manera inteligente...</p>
          <a href="">Leer más</a>
        </article>
      </main>
    </section>
  );
}

export default Blog;
