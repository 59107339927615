
import img1 from "../../assets/Lagunabogotá.png";
import img2 from "../../assets/Lagunade.png";
import img3 from "../../assets/guatativa.png";

function Laguna() {
  return (
    <div>
      <style>
        {`
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }
          header {
            background-color: #333;
            color: #ffff;
            padding: 10px;
            margin-top: 50px;
            text-align: center;
          }
          main {
            padding: 10px;
          }
          h1, h3, h4 {
            color: #333;
            text-align: center;
          }
          p {
            text-align: justify;
            margin-bottom: 20px;
          }
          .image-container {
            display: flex;
            justify-content: space-evenly;
            margin-top: 20px;
            margin: 3px;
          }
          .image-container img {
            width: 300px;
            height: auto;
            margin: 0 10px;
          }
        `}
      </style>
      <header>
  <h2 className="text-white">¡Alquila en 4 rent y viaja a la Laguna del Cacique Guatavita!</h2>
</header>
<main>
  <h3>Descubre la Laguna del Cacique Guatavita: Historia, Geografía y Turismo</h3>
  <p>
    La laguna del cacique Guatavita es un cuerpo hídrico que se encuentra en la cordillera oriental de Colombia, en la vereda Tierra Negra del municipio de Sesquilé, provincia de Almeidas, departamento de Cundinamarca, a unos 75 km al noreste de Bogotá. Es un lugar bajo la jurisdicción de la corporación Corporación Autónoma Regional CAR.
  </p>
  <div className="image-container">
    <img src={img1} alt="Imagen 1" />
  
  </div>
  
  <p>
    La laguna está a una altitud de 3100 m s. n. m. y tiene una temperatura que oscila entre los 5 y 11 °C. Se encuentra enmarcada por laderas cuyas pendientes varían entre los 32 y 38 grados. Se alimenta principalmente por las corrientes subterráneas de agua que se forman por la vegetación del subpáramo. En la laguna se pueden encontrar varias especies de alga que le dan su característico color verdoso, y es habitada por peces como la guapucha.
  </p>
  <div className="image-container">

    <img src={img2} alt="Imagen 2" />
  </div>

  <p>
    La laguna del cacique Guatavita tiene una historia ancestral y está envuelta en la leyenda de El Dorado. Según cuenta la leyenda, en esta laguna se realizaba el ritual de investidura del nuevo Zipa muisca. La balsa muisca, exhibida en el Museo del Oro del Banco de la República de Colombia, es evidencia de estos rituales. La laguna fue objeto de varios intentos de drenaje en busca de tesoros ocultos, pero ninguno tuvo éxito.
  </p>
  <div className="image-container">
    <img src={img3} alt="Imagen 3" />
  </div>
  <h4 className="text-bold ">Acceso</h4>
  <p>
    Para llegar desde Bogotá, se puede acceder por la autopista Norte hasta las afueras de Sesquilé o por la vía Guasca - Sesquilé. La laguna forma parte de un complejo ecoturístico y la entrada tiene un valor de COP 18.000 (en 2023). Los turistas pueden realizar una caminata ecoturística guiada de dos kilómetros hasta llegar a la ladera que enmarca la laguna.
  </p>
</main>


    </div>
  );
}

export default Laguna;
