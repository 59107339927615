import img1 from "../../assets/que-ver-bogota-6.jpg";


function Centro() {
  return (
    <div>
      <style>
        {`
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }
          header {
            background-color: #333;
            color: #ffff;
            padding: 20px;
            margin-top:50px;
            text-align: center;
          }
          main {
            padding: 20px;
          }
          h1 {
            color: #333;
            text-align: center;
          }
          p {
            text-align: center;
          }
          .image-container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }
          .image-container img {
            width: 300px;
            height: auto;
            margin: 0 10px;
          }
        `}
      </style>
      <header id="head">
  <h2 className="text-white">¡Alquila una moto en 4rent y visita la Plaza de Bolívar!</h2>
</header>
<main>
  <h3>Descubre la Plaza de Bolívar: Historia, Características y Entorno</h3>
  <p>
    La Plaza de Bolívar es la plaza principal de la ciudad de Bogotá y de Colombia. Se encuentra ubicada en el centro histórico de la ciudad, entre las carreras Séptima y Octava con calles Décima y Once. Es un lugar emblemático que alberga importantes edificios como el Palacio de Justicia al norte, el Capitolio Nacional al sur, la Catedral Basílica Metropolitana de Bogotá al oriente, y el Palacio Liévano al occidente.
  </p>
  <div className="image-container">
    <img src={img1} alt="Vista panorámica de la Plaza de Bolívar" />

  </div>
  <p>
    La historia de la Plaza de Bolívar se remonta a la época colonial, cuando era sede de un mercado y alrededor se levantaban casas coloniales. En 1846, se erigió en el centro de la plaza una estatua de bronce del general Simón Bolívar, lo que llevó a que la plaza adoptara su nombre en honor al Libertador. A lo largo de los años, ha sido escenario de diversos eventos históricos y ha experimentado cambios en su diseño y configuración.
  </p>

  <p>
    El entorno de la Plaza de Bolívar está lleno de historia y arquitectura destacada. En el costado norte se encuentra el imponente Palacio de Justicia, mientras que al sur se alza el majestuoso Capitolio Nacional. En el costado occidental está el Palacio Liévano, sede de la Alcaldía Mayor de Bogotá, y en el oriente se sitúa la imponente Catedral Basílica Metropolitana de Bogotá.
  </p>
</main>

    </div>
  );
}

export default Centro;
