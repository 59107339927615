import { useState, useEffect } from 'react';
import "./SameMotos.css";
import TarifasComponent from './TarifasComponent';
import axios from 'axios';
import messages from './messages'; 
import ButtonWhatsApp from "./ButtonWhatsApp";
import { TbCircuitMotor, TbCylinderPlus} from "react-icons/tb";
import { AiOutlineSisternode } from "react-icons/ai";
import { SlEnergy } from "react-icons/sl";
import { GiGasPump, GiHorseHead ,GiSpeedometer , GiFullMotorcycleHelmet, GiLifeJacket } from "react-icons/gi";
import { CgChanel } from "react-icons/cg";
import { SiVirginmedia } from "react-icons/si";
import { FaBoxArchive } from "react-icons/fa6";
import { IoPhonePortraitSharp } from "react-icons/io5";
import { RiSecurePaymentFill } from "react-icons/ri";
import { MdPermPhoneMsg } from "react-icons/md";
import { GiColombia } from "react-icons/gi";
import { TbCarCrane } from "react-icons/tb";

import fz250_hero from "../../assets/XTZ150_color_azul.png"
import xtz150Image1 from '../../assets/xtz150_azul.png';
import xtz150Image2 from '../../assets/XTZ150_color_azul.png';
function Xtz150() {
  const phoneNumber = "3154573482";
  const message = messages.page6; 
  const [tasaDeCambio, setTasaDeCambio] = useState(null);
  const cantidadEnPesos = 49999;
  useEffect(() => {
    const fetchTasaDeCambio = async () => {
      try {
        const response = await axios.get('/obtener_tasa_de_cambio.php');
        setTasaDeCambio(response.data.conversion_rates.USD);
      } catch (error) {
        console.error('Error al obtener la tasa de cambio:', error);
      }
   };
    fetchTasaDeCambio();
  }, []);
  const convertirADolares = () => {
    if (tasaDeCambio) {
      return (cantidadEnPesos / tasaDeCambio).toFixed(2);
    }
    return '';
  };
const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    { id: 1, src: xtz150Image1, alt: 'XTZ Yamaha 150 4rent' },
    { id: 2, src: xtz150Image2, alt: 'XTZ Nmax Yamaha 150 4rent' }
    
  ];
  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };
  const alquilerDataXtz150 = {
    semana: [
      { km: '250', cop: '450.000', usd: '120' },
      { km: '700', cop: '590.000', usd: '160' },
      { km: 'Ilimitado', cop: '890.000', usd: '240' }
    ],
    quincena: [
      { km: '1.000', cop: '1\'090.000', usd: '295' },
      { km: '1.500', cop: '1’290.000', usd: '345' },
      { km: 'Ilimitado', cop: '1’490.000', usd: '400' }
    ],
    mes: [
      { km: '1.000', cop: '1’590.000', usd: '430' },
      { km: '2.000', cop: '1’890.000', usd: '510' },
      { km: 'Ilimitado', cop: '2’590.000', usd: '700' }
    ],
    dias: [
      { desc: '24 horas', cop: '149.000', usd: '41' },
      { desc: '2 - 11 días', cop: '139.000', usd: '38' },
      { desc: '12 - 23 días', cop: '109.000', usd: '30' },
      { desc: '24 días o más', cop: '99.000', usd: '27' }
    ],
    kmAdicional: '700',
    horaAdicional: '20.000',
    deposito: '1\'000.000',
  };
  return (







    <section id='xtz' className="max-w-full mx-auto mt-8">
    <div>




    <div className="header-container" style={{ height: '76vh' }}>
    <div className="header-image" style={{ backgroundImage:  `url(${fz250_hero})`, backgroundPosition: 'center', backgroundSize: 'cover', width: '100%' }}></div>
    <div className="overlay"></div>
    <div className="header-content">
        <p className="title">Yamaha Xtz 150</p>
        <div className="subtitle">
            <h1>Alquiler de Yamaha Xtz 150 en Bogotá <span>4rent</span></h1>
            <p>Vive la mejor experiencia al conducir una moto en Colombia.</p>
        </div>
        <div className="button-container">
            <a href="/motocicletas" className="button-outline_o">
                <p>Reserva Hora</p>
            </a>
        </div>
    </div>
    <div className="additional-content"></div>
</div>


<div className='herotitulo'>
<h2 className="titulodehero">XTZ 150</h2>
</div>

   
    <div className="carousel-container">
    <div className="max-w-3xl mx-auto mt-8">
      <div className="carousel-wrapper">
     
        <div className="carousel">
          <img
            src={images[currentImageIndex].src}
            alt={images[currentImageIndex].alt}
            className="carousel-item"
          />
          <div className="carousel-buttons">
            <button className="button-outline button-prev" onClick={handlePrev}>Anterior</button>
            <button className="button-outline button-next" onClick={handleNext}>Siguiente</button>
          </div>
        </div>
      </div>
    </div>
  </div>





 {/* tabla */}
 <table className="table-container">
        <tbody>
          <tr>
            <td colSpan="3">
              <h2 className="section-title">Planes y Tarifas</h2>
            </td>
          </tr>
        </tbody>
      </table>

      <div className='button-container'>
        <div className="button-text">
          <h2>Alquiler por Días</h2>
        </div>
      </div>

      <table className="data-table">
        <tbody>
          <tr>
            <th>Número de Días</th>
            <th>Tarifa COP</th>
            <th>Tarifa USD</th>
          </tr>
          <tr>
            <td>{alquilerDataXtz150.dias[0].desc}</td>
            <td>${alquilerDataXtz150.dias[0].cop}<span className="highlight"> por día</span></td>
            <td>${alquilerDataXtz150.dias[0].usd}</td>
          </tr>
          <tr>
            <td>{alquilerDataXtz150.dias[1].desc}</td>
            <td>${alquilerDataXtz150.dias[1].cop}<span className="highlight"> por día</span></td>
            <td>${alquilerDataXtz150.dias[1].usd}</td>
          </tr>
          <tr>
            <td>{alquilerDataXtz150.dias[2].desc}</td>
            <td>${alquilerDataXtz150.dias[2].cop}<span className="highlight"> por día</span></td>
            <td>${alquilerDataXtz150.dias[2].usd}</td>
          </tr>
          <tr>
            <td>{alquilerDataXtz150.dias[3].desc}</td>
            <td>${alquilerDataXtz150.dias[3].cop}<span className="highlight"> por día</span></td>
            <td>${alquilerDataXtz150.dias[3].usd}</td>
          </tr>
        </tbody>
      </table>

      <div className='button-container'>
        <div className="button-text">
          <h2>Planes Semanales - 7 días</h2>
        </div>
      </div>

      <table className="data-table">
        <tbody>
          <tr>
            <th>Kilómetros</th>
            <th>Tarifa COP</th>
            <th>Tarifa USD</th>
          </tr>
          <tr>
            <td>Plan 250 Km</td>
            <td>${alquilerDataXtz150.semana[0].cop}</td>
            <td>${alquilerDataXtz150.semana[0].usd}</td>
          </tr>
          <tr>
            <td>Plan 700 Km</td>
            <td>${alquilerDataXtz150.semana[1].cop}</td>
            <td>${alquilerDataXtz150.semana[1].usd}</td>
          </tr>
          <tr>
            <td>Plan Ilimitado</td>
            <td>${alquilerDataXtz150.semana[2].cop}</td>
            <td>${alquilerDataXtz150.semana[2].usd}</td>
          </tr>
        </tbody>
      </table>

      <div className='button-container'>
        <div className="button-text">
          <h2>Planes Quincenales - 15 días</h2>
        </div>
      </div>

      <table className="data-table">
        <tbody>
          <tr>
            <th>Kilómetros</th>
            <th>Tarifa COP</th>
            <th>Tarifa USD</th>
          </tr>
          <tr>
            <td>Plan 1,000 Km</td>
            <td>${alquilerDataXtz150.quincena[0].cop}</td>
            <td>${alquilerDataXtz150.quincena[0].usd}</td>
          </tr>
          <tr>
            <td>Plan 1,500 Km</td>
            <td>${alquilerDataXtz150.quincena[1].cop}</td>
            <td>${alquilerDataXtz150.quincena[1].usd}</td>
          </tr>
          <tr>
            <td>Plan Ilimitado</td>
            <td>${alquilerDataXtz150.quincena[2].cop}</td>
            <td>${alquilerDataXtz150.quincena[2].usd}</td>
          </tr>
        </tbody>
      </table>

      <div className='button-container'>
        <div className="button-text">
          <h2>Planes Mensuales - 30 días</h2>
        </div>
      </div>

      <table className="data-table">
        <tbody>
          <tr>
            <th>Kilómetros</th>
            <th>Tarifa COP</th>
            <th>Tarifa USD</th>
          </tr>
          <tr>
            <td>Plan 1,000 Km</td>
            <td>${alquilerDataXtz150.mes[0].cop}</td>
            <td>${alquilerDataXtz150.mes[0].usd}</td>
          </tr>
          <tr>
            <td>Plan 2,000 Km</td>
            <td>${alquilerDataXtz150.mes[1].cop}</td>
            <td>${alquilerDataXtz150.mes[1].usd}</td>
          </tr>
          <tr>
            <td>Plan Ilimitado</td>
            <td>${alquilerDataXtz150.mes[2].cop}</td>
            <td>${alquilerDataXtz150.mes[2].usd}</td>
          </tr>
        </tbody>
      </table>
      {/* tabla */}

   



      <div className="additional-container">
  <h2 className="additional-title">Adicionales</h2>
  <table className="additional-table">
    <tbody>
      <tr>
        <td className="additional-cell">
          <p className="additional-item-title">Kilómetro adicional:</p>
        </td>
        <td className="additional-cell">
          <p className="additional-item-price">$<span>{alquilerDataXtz150.kmAdicional}</span> COP</p>
        </td>
      </tr>
      <tr>
        <td className="additional-cell">
          <p className="additional-item-title">Hora adicional:</p>
        </td>
        <td className="additional-cell">
          <p className="additional-item-price">$<span>{alquilerDataXtz150.horaAdicional}</span> COP</p>
        </td>
      </tr>
      <tr>
        <td className="additional-cell">
          <p className="additional-item-title">Depósito / fianza:</p>
        </td>
        <td className="additional-cell">
          <p className="additional-item-price">$<span>{alquilerDataXtz150.deposito}</span> COP</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>








  <div className="w-full  overflow-x-auto">
<h2 className='text-2xl font-bold mb-8 text-center mt-8'>Adicionales</h2>
  <table className="border-collapse w-full md:w-23 lg:w-1/2 xl:w-1/3 mx-auto">
    
    <tbody>
      <tr>

        <td className="p-2 border-b border-gray-300 hover:border-primary">
          <p className="text-lg text-black font-bold">Kilómetro adicional:</p>
        </td>
        <td className="p-2 border-b border-gray-300 hover:border-primary text-black">
          <p className="text-black">$<span className="text-black">{alquilerDataXtz150.kmAdicional}</span> COP</p>
        </td>

      </tr>

      <tr>
        <td className="p-2 border-b border-gray-300 hover:border-primary">
          <p className="text-lg text-black font-bold">Hora adicional:</p>
        </td>
        <td className="p-2 border-b border-gray-300 hover:border-primary text-black">
          <p className="text-black">$<span className="text-black">{alquilerDataXtz150.horaAdicional}</span> COP</p>
        </td>
      </tr>
      <tr>
        <td className="p-2 border-b border-gray-300 hover:border-primary">
          <p className="text-lg text-black font-bold">Depósito / fianza:</p>
        </td>
        <td className="p-2 border-b border-gray-300 hover:border-primary text-black">
          <p className="text-black">$<span className="text-black">{alquilerDataXtz150.deposito}</span> COP</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>




<div style={{ 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  height: '20vh',
}}>
  <ButtonWhatsApp style={{ 
    backgroundColor: '#28A745', 
    color: '#fff', 
    border: '1px solid #218838', 
    padding: '12px 24px', 
    borderRadius: '8px', 
    fontSize: '18px', 
    fontWeight: '600', 
    textTransform: 'uppercase', 
    letterSpacing: '1px',
    textDecoration: 'none',
    display: 'inline-block',
    cursor: 'pointer',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
    transition: 'background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease', // Añade la transición de sombra
  }} phoneNumberR={phoneNumber} message={message} />
</div>


  <div className="banner">
<div className="banner-content">
<h3 className="banner-title  text-3xl font-bold mb-4 text-center">Cobertura Completa para tu Tranquilidad</h3>
</div>
</div>
</div>

  {convertirADolares()}
        <TarifasComponent />
        <div className="banner">
<div className="banner-content">
<h3 className="banner-title text-3xl font-bold mb-4 text-center">Especificaciones Técnicas</h3>
</div>
</div>
        <div className="max-w-4xl mx-auto mt-8">
  <table className="w-full my-8">
    <tbody className="bg-gray-10">
      <tr>
        <td className="border-b border-gray-300 py-2"><TbCircuitMotor /></td>
        <th className="border-b border-gray-300 py-2">Motor:</th>
        <td className="border-b border-gray-300 py-2">Monocilíndrico, 4 tiempos, SOHC, refrigerado por aire</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><TbCylinderPlus /></td>
        <th className="border-b border-gray-300 py-2">Cilindrada:</th>
        <td className="border-b border-gray-300 py-2">149 cc</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><AiOutlineSisternode /></td>
        <th className="border-b border-gray-300 py-2">Alimentación:</th>
        <td className="border-b border-gray-300 py-2">Carburador</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><SiVirginmedia /></td>
        <th className="border-b border-gray-300 py-2">Diámetro x carrera:</th>
        <td className="border-b border-gray-300 py-2">57.3 mm x 57.9 mm</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"> <GiHorseHead /></td>
        <th className="border-b border-gray-300 py-2">Potencia:</th>
        <td className="border-b border-gray-300 py-2">12.5 HP (9.2 kW) @ 7,500 rpm</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><CgChanel /></td>
        <th className="border-b border-gray-300 py-2">Torque Máximo:</th>
        <td className="border-b border-gray-300 py-2">12.8 Nm (1.3 kgf-m) @ 6,000 rpm</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><SlEnergy /></td>
        <th className="border-b border-gray-300 py-2">Arranque:</th>
        <td className="border-b border-gray-300 py-2">Eléctrico y de patada</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><GiGasPump /></td>
        <th className="border-b border-gray-300 py-2">Capacidad de combustible:</th>
        <td className="border-b border-gray-300 py-2">12 L</td>
      </tr>
      <tr>
        <td className="border-b border-gray-300 py-2"><GiSpeedometer /></td>
        <th className="border-b border-gray-300 py-2">Transmisión:</th>
        <td className="border-b border-gray-300 py-2">Manual, 5 velocidades, cadena</td>
      </tr>
    </tbody>
  </table>
</div>

<div className="banner">
<div className="banner-content">
<h3 className="banner-title text-3xl font-bold mb-4 text-center">Lo incluido dentro de las tarifas</h3>
</div>
</div>

<div className="container mx-auto">
      <table className="table-auto border-collapse border">
        <thead>
          <tr>
            <th className=" text-start px-4 py-2 text-xl"></th>
            <th className="  px-4 py-2 text-xl ">Cantidad</th>
          </tr>
        </thead>
       
       





        <tbody>
  <tr>
    <td className="table-cell-icon">
      <GiFullMotorcycleHelmet className="icon" /> Cascos
    </td>
    <td className="table-cell">2</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <GiLifeJacket className="icon" /> Chalecos reflectivos
    </td>
    <td className="table-cell">2</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <FaBoxArchive className="icon" /> Baúl de 40 Litros (Trunk)
    </td>
    <td className="table-cell">1</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <IoPhonePortraitSharp className="icon" /> Soporte para celular en aluminio
    </td>
    <td className="table-cell">1</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <RiSecurePaymentFill className="icon" /> Seguro contra robo (Deducible del 15%)
    </td>
    <td className="table-cell">✔</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <MdPermPhoneMsg className="icon" /> Asistencia Telefónica 24/7 (Emergencias)
    </td>
    <td className="table-cell">✔</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <GiColombia className="icon" /> Mantenimientos Incluidos a nivel Nacional
    </td>
    <td className="table-cell">✔</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <RiSecurePaymentFill className="icon" /> Seguro contra pérdidas totales (Deducible del 15%)
    </td>
    <td className="table-cell">✔</td>
  </tr>
  <tr>
    <td className="table-cell-icon">
      <TbCarCrane className="icon" /> Grúa 24/7 a nivel nacional (Problemas mecánicos o accidente)
    </td>
    <td className="table-cell">✔</td>
  </tr>
</tbody>













      </table>
    </div>



    <div className="r6j8-qlsj-container">
  <h2 className="r6j8-qlsj-title">¡Listo para desatar la emoción sobre dos ruedas? </h2>
  <div className="r6j8-qlsj-grid">
    <div className="r6j8-qlsj-button-container">
      <a href="/contacto" className="r6j8-qlsj-button">¡Comunícate por WhatsApp!</a>
    </div>
    <div className="r6j8-qlsj-text-container">
      <p className="r6j8-qlsj-text">
      Conoce la Yamaha XTZ 150, una motocicleta diseñada para la aventura y la versatilidad. Equipada con un motor confiable y una suspensión robusta, la XTZ 150 te lleva a explorar caminos urbanos y rurales con facilidad y comodidad. Prepárate para disfrutar de emocionantes viajes y descubrimientos con la Yamaha XTZ 150.
       </p>
    </div>
  </div>
</div>
  </section>
  )
}
export default Xtz150