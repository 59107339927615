import  { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Fz250 from "../assets/FZ250_02.png";
import Dominar400 from "../assets/Dominar400_01.png";
import Xre300 from "../assets/XRE01.png";
import './Same.css';
import AOS from "aos";
import "aos/dist/aos.css";

const motoList = [

    {
        link: "../fz250",
        name: "FZ ABS",
        price: 179000,
        image: Fz250,
        cilindraje: "250 Cc",
        marca: "Yamaha",
        aosDelay: 0,
    },
    {
        link: "../dominar400",
        name: "Bajaj Dominar 400",
        price: 249000,
        image: Dominar400, 
        cilindraje: "400 Cc",
        marca: "Bajaj",
        aosDelay: 200, 
    },
    {
        link: "../xre300",
        name: "Honda XRE300",
        price: 290000,
        image: Xre300, 
        cilindraje: "300 Cc",
        marca: "Honda",
        aosDelay: 400, 
    },
];

const Manuales = () => {
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        AOS.init({
            duration: 600,
            once: true,
        });
    }, []);

    return (
        <div className="pb-24">
            <div className="container mt-20">
                <h1
                    data-aos="fade-up"
                    className="text-4xl font-semibold mb-3"
                >
                    Todas nuestras motos
                </h1>
                <p data-aos="fade-up" data-aos-delay="400" className="text-lg pb-10">
                    ¿Qué tipo de imagen es?
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-16">
                    {motoList.map((data, index) => (
                        <div
                            key={index}
                            data-aos="fade-up"
                            data-aos-delay={data.aosDelay}
                            className="space-y-3 border-2 border-gray-300 hover:border-primary p-3 rounded-xl relative group"
                            style={{ maxWidth: "400px" }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <div className="w-full h-[200px]">
                                <img
                                    src={data.image}
                                    alt={data.name}
                                    className="w-full h-full object-contain group-hover:sm:translate-x-16 duration-700"
                                />
                            </div>
                            <div className="space-y-2">
                                <h1 className="text-black font-bold font-size-30">{data.name}</h1>
                                <div className={`flex justify-evenly items-center text-xl font-semibold ${isHovered ? 'yellowBackground' : ''}`}>
                                    <p>${data.price}/día</p>
                                    <Link to={data.link}>Ver más</Link>
                                </div>
                            </div>
                            <p className="text-xl text-primary font-semibold absolute top-0 left-3">
                                {data.marca}
                            </p>
                            <p className="text-xl font-semibold absolute top-8 left-3">
                                {data.cilindraje}
                            </p>
                        </div>
                    ))}
                </div>
                <div className="grid place-items-center mt-8">
                <Link to="/motocicletas" data-aos="fade-up" className="button-outline">
          Ver más
          </Link>
                </div>
            </div>
        </div>
    );
};

export default Manuales;
