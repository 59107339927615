import img1 from "../../assets/JardinBotanico.png";


function Ruiz() {
  return (
    <div>
      <style>
        {`
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
          }
          header {
            background-color: #333;
            color: #ffff;
            padding: 20px;
            margin-top:50px;
            text-align: center;
          }
          main {
            padding: 20px;
          }
          h1 {
            color: #333;
            text-align: center;
          }
          p {
            text-align: center;
          }
          .image-container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }
          .image-container img {
            width: 300px;
            height: auto;
            margin: 0 10px;
          }
        `}
      </style>
      <header id="head">
        <h2 className="text-white">¡Ve en moto al Jardín Botánico de Bogotá!</h2>
      </header>
      <main>
        <h3>Jardín Botánico José Celestino Mutis</h3>
        <p>
          El Jardín Botánico de Bogotá es un centro de investigación, conservación y divulgación de la diversidad de las especies vegetales en Bogotá, capital de Colombia. Fundado en 1955 por el sacerdote Enrique Pérez Arbeláez.
        </p>
        <p>
          Con una extensión aproximada de 195,000 m², el jardín cuenta con cerca de 46,645 individuos, 304 familias y 903 especies de plantas. Ofrece diversas actividades educativas, recorridos guiados y exhibiciones para que los visitantes puedan disfrutar y aprender sobre la flora de la región.
        </p>
        <div className="image-container">
          <img src={img1} alt="Imagen del Jardín Botánico" />

        </div>
      </main>
    </div>
  );
}

export default Ruiz;
