import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const ButtonWhatsApp = ({ phoneNumberR, message }) => {
  const openWhatsApp = () => {
    const url = `https://wa.me/${phoneNumberR}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <Button
      className="button-whatsapp"
      style={{
        backgroundColor: "#25D366",
        color: "white",
        borderRadius: "10px",
        padding: "10px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        transition: "background-color 0.3s",
        cursor: "pointer",
        textDecoration: "none",
        border: "none",
      }}
      onClick={openWhatsApp}
      variant="contained"
    >
      <WhatsAppIcon style={{ marginRight: "10px" }} />
      <Typography style={{  color: "white" }}>Reservar con un asesor</Typography>
    </Button>
  );
};

ButtonWhatsApp.propTypes = {
  phoneNumberR: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default ButtonWhatsApp;
