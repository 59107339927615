import { useEffect } from 'react'; 
import './WhatsappInvite.css';
import PropTypes from 'prop-types';

const imgo = "https://res.cloudinary.com/diyruqkor/image/upload/v1726074647/4%20rent/vzazopzl96ge5gqhc07b.webp";

const WhatsappInvite = ({ phoneNumber }) => {
  useEffect(() => {
    const videoElement = document.querySelector('.whatsapp-invite-video');
    if (videoElement) {
      videoElement.play().catch(error => {
        console.error('Error intentando reproducir el video:', error);
      });
    }
  }, []);

  return (
    <div className="whatsapp-invite-container mt-8">
      <div className="whatsapp-invite-content">
        <div className="whatsapp-invite-text">
          <h2>¿Quieres contactarnos a través de WhatsApp?</h2>
          <a 
            href={`https://wa.me/${phoneNumber}`} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="whatsapp-invite-link"
          >
            ¡Haz clic aquí para contactarnos en WhatsApp!
          </a>
        </div>
        <div className="whatsapp-invite-image">
          <img src={imgo} alt="Imagen de WhatsApp" loading="lazy" />
        </div>
        <p>Contáctanos fácilmente a través de nuestra línea de WhatsApp. Nuestros expertos están disponibles para brindarte asistencia personalizada.</p>
        <p>La comunicación con nuestra comunidad de entusiastas de las motos es esencial. Por esta razón, hemos establecido un canal directo con nuestros asesores para resolver todas tus consultas y ayudarte a comenzar tu aventura en dos ruedas hoy mismo.</p>
      </div>
    </div>
  );
};

WhatsappInvite.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};

export default WhatsappInvite;
