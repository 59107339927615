
const messages = {
    page1: "¡Hola 4rent! Deseo reservar la moto Dominar",
    page2: "¡Hola 4rent! Deseo reservar la moto Fz 250",
    page3: "¡Hola 4rent! Deseo reservar la moto Nmax 155",
    page4: "¡Hola 4rent! Deseo reservar la moto Xpulse 200",
    page5: "¡Hola 4rent! Deseo reservar la moto Xre 300",
    page6: "¡Hola 4rent! Deseo reservar la moto Xtz 150",
 
  };
  
  export default messages;
  